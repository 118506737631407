// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
// import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import map from 'lodash/map'
import filter from 'lodash/filter'
import orderBy from 'lodash/orderBy'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import { compiler } from 'markdown-to-jsx'

import Row from 'antd/lib/row'
import 'antd/lib/row/style/css'

import Col from 'antd/lib/col'
import 'antd/lib/col/style/css'

import Tabs from 'antd/lib/tabs'
import 'antd/lib/tabs/style/css'

import Timeline from 'antd/lib/timeline'
import 'antd/lib/timeline/style/css'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import Breadcrumbs from '../components/breadcrumbs'
import '../components/breadcrumbs/style.less'

import StandardPageWrapper from '../components/standard-page-wrapper'
import '../components/standard-page-wrapper/style.less'

import BlockParser from '../components/block-parser'
import '../components/block-parser/style.less'

import strContains from '../methods/str-contains'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
// const { Fragment } = React
const pageSchema = {
  title: 'Team',
  slug: '/team',
  abstract: 'Team',
  breadcrumbs: [
    { title: 'Homepage', slug: '' },
    { title: 'Team', slug: '/team' },
  ],
}

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Images
// ----------------------------------------------------------------------------
export const query = graphql`
  query TeamPageQuery {
    allTeamJson {
      edges {
        node {
          title
          subTitle
          type
          order
          cover {
            childImageSharp {
              gatsbyImageData(
                width: 600
                placeholder: TRACED_SVG
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
          bioBlocks {
            type
            content
          }
          meaningBlocks {
            type
            content
          }
          offeringBlocks {
            type
            altContent
            content
          }
          include
        }
      }
    }
  }
`

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Page */
const Page = (props) => {
  const {
    allTeamJson: { edges },
  } = props.data

  const nodes = map(edges, 'node')
  const includedNodes = filter(nodes, ['include', true])
  const orderedNodes = orderBy(includedNodes, ['order'], ['asc'])

  return (
    <StandardPageWrapper pageSchema={pageSchema} {...props}>
      <Breadcrumbs breadcrumbs={pageSchema.breadcrumbs} />
      <h1>Team</h1>
      <p style={{ maxWidth: 'unset', columnCount: 2, textAlign: 'justify' }}>
        Meet the people behind the Aura Network project! The Aura initiative
        could not have come into fruition without the combined efforts from
        various contributors. Since the commencement of the Aura initiative in
        2019, our team has been diverse in nature consisting of proactive
        individuals from different generations and fields in Auroville. It takes
        a village to reinvent our economy!
      </p>
      <br />
      {map(orderedNodes, (node) => (
        <div
          style={{
            borderTop:
              strContains(node.subTitle, 'Project Holder') === true
                ? '4px solid var(--orange1)'
                : '4px solid var(--gold1)',
            marginBottom: '1.1rem',
          }}
        >
          <Row
            gutter={[
              { xs: 0, sm: 0, md: 0, lg: 0, xl: 0, xxl: 0 },
              { xs: 0, sm: 0, md: 0, lg: 0, xl: 0, xxl: 0 },
            ]}
          >
            <Col
              xs={24}
              sm={5}
              md={5}
              lg={5}
              xl={5}
              xxl={5}
              style={{
                background:
                  strContains(node.subTitle, 'Project Holder') === true
                    ? 'var(--orange1)'
                    : 'var(--gold1)',
                borderBottomLeftRadius: '0.55rem',
                borderBottomRightRadius: '0.55rem',
                overflow: 'hidden',
              }}
            >
              <GatsbyImage image={getImage(node.cover)} />
              <div style={{ padding: '0.55rem' }}>
                <h2
                  style={{
                    marginBottom: 0,
                    color:
                      strContains(node.subTitle, 'Project Holder') === true
                        ? '#fff'
                        : 'var(--text-color)',
                  }}
                >
                  {node.title}
                </h2>
                <p
                  style={{
                    color:
                      strContains(node.subTitle, 'Project Holder') === true
                        ? '#fff'
                        : 'var(--text-color)',
                  }}
                >
                  <strong>{compiler(node.subTitle, { wrapper: null })}</strong>
                </p>
              </div>
            </Col>
            <Col xs={24} sm={10} md={12} lg={12} xl={12} xxl={12}>
              <div style={{ marginLeft: '1.1rem', padding: '0.55rem 0rem' }}>
                <Tabs defaultActiveKey="1">
                  <Tabs.TabPane
                    tab={<h3 style={{ marginBottom: 0 }}>Short bio</h3>}
                    key="1"
                  >
                    {map(node.bioBlocks, (block) => (
                      <BlockParser block={block} />
                    ))}
                  </Tabs.TabPane>
                  <Tabs.TabPane
                    tab={
                      <h3 style={{ marginBottom: 0 }}>
                        What does Aura mean to you?
                      </h3>
                    }
                    key="2"
                  >
                    {map(node.meaningBlocks, (block) => (
                      <BlockParser block={block} />
                    ))}
                  </Tabs.TabPane>
                </Tabs>
              </div>
            </Col>
            <Col xs={24} sm={8} md={7} lg={7} xl={7} xxl={7}>
              <div style={{ marginLeft: '1.1rem', padding: '0.55rem 0rem' }}>
                {map(node.offeringBlocks, (block) => (
                  <BlockParser block={block} />
                ))}
              </div>
            </Col>
          </Row>
        </div>
      ))}
      <Row
        gutter={[
          { xs: 0, sm: 0, md: 0, lg: 0, xl: 0, xxl: 0 },
          { xs: 0, sm: 0, md: 0, lg: 0, xl: 0, xxl: 0 },
        ]}
      >
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <div
            style={{
              borderTop: '4px solid var(--orange1)',
              marginTop: '1.1rem',
              padding: '1.1rem',
              background: 'var(--gray2)',
            }}
          >
            <h2>Timeline of Our Team Roster</h2>
            <Timeline mode="alternate">
              <Timeline.Item>
                <p>
                  <strong>
                    Phase 1, February 2019 - May 2019
                    <br />
                    Understanding Auroville’s economy
                  </strong>
                </p>
                <p>
                  <br />
                  B
                  <br />
                  Dan Be
                  <br />
                  Hye Jeong
                  <br />
                  Malcom
                  <br />
                  Matthew
                </p>
              </Timeline.Item>
              <Timeline.Item>
                <p>
                  <strong>
                    Phase 2, September 2019 - November 2020
                    <br />
                    Contextualizing and refining the Aura concept
                  </strong>
                </p>
                <p>
                  Ahilya
                  <br />
                  Jonas
                  <br />
                  Lucrezia
                  <br />
                  Marie
                  <br />
                  Mathilde
                  <br />
                  Sudipta
                  <br />
                  Tina
                  <br />
                  Quentin
                </p>
              </Timeline.Item>
              <Timeline.Item>
                <p>
                  <strong>
                    Phase 3, April 2020 - September 2020
                    <br />
                    Developing the Aura Network mobile application
                  </strong>
                </p>
                <p>
                  Amarnath
                  <br />
                  András
                  <br />
                  Kshitij
                  <br />
                  Matej
                  <br />
                  Mansur
                  <br />
                  Ribhu
                  <br />
                  Rima
                </p>
              </Timeline.Item>
              <Timeline.Item>
                <p>
                  <strong>
                    Phase 4, December 2020 - February 2021
                    <br />
                    Engaging in commnunity outreach
                  </strong>
                </p>
                <p>
                  <br />
                  Alessandra
                  <br />
                  Christian
                  <br />
                  Gun Hui
                  <br />
                  Luke
                  <br />
                  PRISMA Design
                  <br />
                  Pranav
                  <br />
                  Yogini
                  <br />
                  Mansu
                  <br />
                  Tina
                </p>
              </Timeline.Item>
              <Timeline.Item>
                <p>
                  <strong>Phase 5, Ongoing</strong>
                </p>
                <p>led by the Core Team</p>
              </Timeline.Item>
            </Timeline>
          </div>
        </Col>
      </Row>
    </StandardPageWrapper>
  )
}

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default Page
